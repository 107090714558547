<div class="bm-cancel" v-if="show" :style="{top: top + 'px'}" v-cloak>
  <div class="bm-background" :style="{backgroundColor: background_color, opacity: background_alpha}"></div>

  <form class="bm-cancel-modal" autocomplete="on" @submit.prevent="sendCancel">
    <div class="bm-modal-header">
      <span class="bm-header-icon" v-html="icon" v-if="icon"></span>
      <h1 class="bm-header-title" v-html="title" v-if="title"></h1>
      <h2 class="bm-header-subtitle" v-html="subtitle" v-if="subtitle"></h2>
    </div>

    <div class="bm-modal-callout" v-show="callout" v-html="callout"></div>
    <div class="bm-modal-error" v-show="error" v-html="error"></div>

    <div class="bm-modal-form">
      <div class="bm-form-row" v-if="reasons.length"> <!-- Reasons -->
        <label class="bm-row-cell" v-for="reason in ordered_reasons">
          <input class="bm-row-radio" type="radio" :value="reason.id" v-model="selected_reason">
          <span class="bm-row-radio-mirror"></span>
          {{reason.text}}
        </label>
      </div>

      <div class="bm-form-row"> <!-- Comment -->
        <label class="bm-row-cell">
          <textarea class="bm-row-textarea" :placeholder="comment_text || (comment_required ? 'What would you like to share?' : 'Anything you want to share? (Optional)')" v-model="comment"></textarea>
        </label>
      </div>
    </div>

    <div class="bm-modal-action">
      <button class="bm-action-submit" type="submit" :style="{backgroundColor: button_color}">
        <i class="bm-loading" v-show="loading"></i>
        {{cancel_button_text || 'Cancel Account'}}
      </button>

      <a href="javascript:void(0)" class="bm-nevermind" :style="{color: link_color}" @click="abortCancel">{{back_link_text || 'Nevermind, I don\'t want to cancel.'}}</a>
    </div>
  </form>
</div>
