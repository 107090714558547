/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
/* global GIT_SHA */

// f0a4b228-4621-459f-a877-a7ab86bca691
// sub_6tyg804P3R7E81
// http://cancel.lvh.me:8080/?ci_api_integrated=false&access_token_id=f0a4b228-4621-459f-a877-a7ab86bca691&subscription_oids=sub_6tyg804P3R7E81

import './scss/application.scss';
import './scss/barecancel.scss';

import fetch from 'unfetch';

import Vue from 'vue';

import Raven from 'raven-js';
import getComposedPath from 'get-composed-path';

import extend from 'lodash/extend';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import shuffle from 'lodash/shuffle';
import initial from 'lodash/initial';
import last from 'lodash/last';
import each from 'lodash/each';

import withRender from './html/barecancel.html';

const isDev = !!(window.location.hostname === 'cancel.lvh.me'
  || window.location.hostname === 'dashboard.test');

// Support IE9+
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
                              || Element.prototype.webkitMatchesSelector;
}

// Helper methods
// Check status of `fetch` response
function checkStatus(response) {
  if (response.ok) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  return Promise.reject(error);
}

// Helper function to get the values from the search string
function parseSearchString() {
  const clean = {};

  let raw = window.location.search.substring(1);
  raw = raw.split('&');

  each(raw, (d) => {
    const pair = d.split('=');

    if (pair.length === 2) {
      const value = decodeURIComponent(pair[1]);
      clean[pair[0]] = (() => {
        if (value === 'true') {
          return true;
        }

        if (value === 'false') {
          return false;
        }

        return value;
      })();
    }
  });

  delete clean.show;
  return clean;
}

Raven
  .config('https://410a85db6b0546a0b5e243adffcd3530@sentry.baremetrics.io/6', {
    release: GIT_SHA,
    environment: isDev ? 'development' : 'production',
    whitelistUrls: isDev ? [] : [/baremetrics-barecancel\.baremetrics\.com/, /barecancel\.s3-website-us-east-1\.amazonaws\.com/],
    captureUnhandledRejections: false,
    autoBreadcrumbs: true,
    shouldSendCallback(data) {
      if (
        data.extra
      && data.extra.url
      && /(baremetrics|barecancel)/.test(data.extra.url) === false
      ) return false;

      if (
        data.extra
      && data.extra.dataTypes
      && data.extra.dataTypes.indexOf('html') !== -1
      ) return false;

      if (window.barecancel.params.callback_error) window.barecancel.params.callback_error(data);

      return true;
    },
  })
  .install();

const logErrorToSentry = (err) => {
  Raven.captureMessage(
    get(err, 'response.data.error',
      isObject(err.response)
        ? err.response.message
            || err.response.reason
            || err.response.statusText
            || err.response.status
            || 'Undefined Error'
        : err), {
      extra: {
        ...err,
        'request-id': get(err, 'response.headers.request-id'),
      },
    },
  );
};

window.barecancel = window.barecancel || {};

window.barecancel.load = () => {
  window.barecancel.loading = true;

  // Build and run the vue template
  function buildVue(params) {
    params.element = params.element || document.querySelector('barecancel');

    if (!params.element) {
      const element = document.createElement('div');
      element.setAttribute('data-barecancel', 'data-barecancel');
      params.element = element;
      document.body.appendChild(params.element);
    }

    window.barecancel.vue = new Vue(withRender({
      el: params.element,
      data() {
        return extend({
          top: 0,
          show: false,
          error: false,
          loading: false,
          comment: null,
          selected_reason: null,
          callback_send: null,
          callback_abort: null,
          callback_error: null,
          comment_text: null,
          comment_required: false,

          cancel_button_text: null,
          back_link_text: null,
          reason_mandatory: true,
          randomize_reasons: false,
        }, params);
      },
      computed: {
        ordered_reasons() { // Shuffle reasons while keeping the last reason as the last reason
          if (this.randomize_reasons) {
            return [
              ...shuffle(initial(this.reasons)),
              last(this.reasons),
            ];
          }

          return this.reasons;
        },
      },
      mounted() {
        Raven.setExtraContext(params);

        window.barecancel.loading = false;

        if (window.barecancel.loaded) {
          window.barecancel.loaded();
        }
      },
      watch: {
        show() {
          this.top = window.pageYOffset;
        },
        selected_reason() {
          this.error = false;
        },
      },
      methods: {
        sendCancel() {
          this.error = false;

          if (this.selected_reason === null && this.reason_mandatory) {
            this.error = 'Please select a cancellation reason';
            return;
          }

          if (this.comment_required && !this.comment) {
            this.error = typeof this.comment_required === 'string' ? this.comment_required : 'Please add a cancellation comment';
            return;
          }

          this.loading = true;

          const callback = (errorOrResponse) => {
            if (this.callback_send) {
              this.callback_send(this.$data, errorOrResponse);
            }

            this.loading = false;
            this.selected_reason = null;
            this.comment = null;
            this.show = false;
          };

          if (!params.access_token_id || (!params.token && !params.customer_oid && (!params.subscription_oids || params.subscription_oids.length === 0 || params.subscription_oids.find((oid) => oid.match(/CUSTOMER|SUBSCRIPTION|EXAMPLE|TEST/gi))))) {
            callback();
            return;
          }

          fetch(`${params.api_url}/events`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              test_mode: typeof params.test_mode === 'boolean' ? params.test_mode : !JSON.parse(params.ci_api_integrated),
              access_token_id: params.access_token_id,
              subscription_oids: params.subscription_oids,
              customer_oid: params.customer_oid,
              token: params.token,
              reason_id: this.selected_reason,
              comment: this.comment,
            }),
          })
            .then(checkStatus)
            .then((res) => res.json())
            .then((res) => {
              callback(res);
            })
            .catch((err) => {
              callback(err);
              logErrorToSentry(err);
            });
        },
        abortCancel() {
          if (this.callback_abort) {
            this.callback_abort();
          }

          this.selected_reason = null;
          this.comment = null;
          this.show = false;
        },
      },
    }));
  }

  // Load styles and vue
  function prepareVue(params) {
    // Get stylesheet
    const link = document.createElement('link');
    link.rel = 'stylesheet';

    const assetsURL = (isDev ? window.location.origin : 'https://baremetrics-barecancel.baremetrics.com');
    link.href = `${params.assets_url ? params.assets_url : assetsURL}/css/barecancel.css`;

    document.head.appendChild(link);

    buildVue(params);

    const sendCallback = params.callback_send;

    document.body.addEventListener('click', (e) => {
      const path = getComposedPath(e);

      const matchingTrigger = path.filter((node) => {
        if (node === document || node === window) {
          return false;
        }

        if (typeof node.matches === 'function') {
          return node.matches(params.trigger);
        }

        return false;
      });

      if (matchingTrigger.length > 0) {
        e.preventDefault();

        window.barecancel.open();
        window.barecancel.vue.callback_send = (data, errorOrResponse) => {
          if (sendCallback) {
            sendCallback(window.barecancel.vue.$data || data, errorOrResponse);
          }

          if (e.target.href) {
            window.location = e.target.href;
          } else if (e.target.submit) {
            e.target.submit();
          } else if (e.target.form) {
            e.target.form.submit();
          } else if (e.target.parentNode && e.target.parentNode.submit) {
            e.target.parentNode.submit();
          } else if (e.target.parentElement && e.target.parentElement.submit) {
            e.target.parentElement.submit();
          }
        };
      }
    });
  }

  // Check the customer status
  function getCancelObject() {
    let params = window.barecancel ? window.barecancel.params : {};
    params = extend(parseSearchString(), params);

    params.trigger = params.trigger || '#barecancel-trigger';
    params.api_url = isDev ? 'http://cancellation-insights.test/v1' : 'https://cancel.baremetrics.com/v1';
    params.subscription_oids = (() => {
      if (Array.isArray(params.subscription_oids)) {
        return params.subscription_oids.filter(Boolean);
      }

      if (typeof params.subscription_oids === 'string') {
        return params.subscription_oids.split(',').filter(Boolean);
      }

      return [];
    })();

    if (params.subscription_oids.length === 0) {
      params.subscription_oids = undefined;
    }

    Raven.setUserContext({
      access_token_id: params.access_token_id,
      subscription_oids: params.subscription_oids,
      customer_oid: params.customer_oid,
      token: params.token,
    });

    // Load the demo
    if (!params.access_token_id) {
      params = extend({
        icon: '😢',
        show: false,
        title: 'We\'re sad to see you go...',
        subtitle: 'Before you cancel, please let us know the reason you are leaving. Every bit of feedback helps!',
        callout: null,
        reasons: [
          { id: 0, text: 'Does not meet my needs' },
          { id: 1, text: 'App is not performing well' },
          { id: 2, text: 'I don\'t need it anymore' },
          { id: 3, text: 'I found something better' },
          { id: 4, text: 'It\'s too expensive' },
          { id: 5, text: 'Other' },
        ],
        button_color: '#E84C85',
        link_color: '#6078FF',
        background_color: '#292940',
        background_alpha: 1,
        callback_send() {
          // eslint-disable-next-line no-console
          console.log('[DEMO MODE] Account cancelled');
        },
        callback_abort() {
          // eslint-disable-next-line no-console
          console.log('[DEMO MODE] Account not cancelled');
        },
      }, params);

      prepareVue(params);
    } else {
      // Get the Barecancel Form
      fetch(`${params.api_url}/form?access_token_id=${params.access_token_id}`)
        .then(checkStatus)
        .then((res) => res.json())
        .then((data) => {
          params = extend(data, params);

          Raven.setExtraContext(params);

          // Now load the vue instance
          prepareVue(params);
        })
        .catch((e) => {
          window.barecancel.loading = false;

          if (window.barecancel.loaded) {
            window.barecancel.loaded();
          }

          logErrorToSentry(e);
        });
    }
  }

  getCancelObject();
};

window.barecancel.open = () => {
  window.barecancel.vue.show = true;
};

window.barecancel.close = () => {
  window.barecancel.vue.show = false;
};

(() => window.barecancel.load())();
